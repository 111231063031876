import { Button } from "@mindstonehq/ui";
import { Link } from "@remix-run/react";
import React from "react";

interface FeedbackProps {
  code: string | number;
  title: string;
  subtitle?: string;
  cta?: JSX.Element;
}

export function Feedback(props: FeedbackProps) {
  const { code, cta, title, subtitle } = props;

  return (
    <>
      <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 w-full">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-primary sm:text-5xl">
              {code}
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-theme-border-medium sm:pl-6">
                <h3 className="tracking-tight sm:text-5xl text-muted-foreground">
                  {title}
                </h3>
                <p className="mt-2 text-placeholder-foreground">{subtitle}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                {cta ? (
                  cta
                ) : (
                  <Link to="/">
                    <Button variant="secondary">Go back home</Button>
                  </Link>
                )}
                <a type="button" id="ms-intercom-launcher">
                  <Button>Contact support</Button>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
